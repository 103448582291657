.tableContainer {
	width: 100% ;
	height: 100%;
}

.tableHeading {
	display: flex;
	justify-content: space-between;
	height: 58px;
	margin-bottom: 18px;
}

.tableHeadingName {
	align-self: flex-start;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	color: #1e496b;
	margin: 0;
}

.tableHeadingBtnContainer {
	position: absolute;
	right: 33px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.hireBtn {
	align-self: flex-end;
	width: 132px;
	height: 42px;
	background: #f27480;
	border-radius: 5px;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	color: #fff;
	border: none;
	outline: none;
	cursor: pointer;
}

.statusBtn {
	height: 42px;
	border: 1.5px solid #b9bdc1;
	box-sizing: border-box;
	border-radius: 5px;
	background-color: #fff;
	padding: 0 40px;
	margin-right: 20px;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	color: #b9bdc1;
	outline: none;
	cursor: pointer;
}

.showBtn {
	align-self: flex-end;
	height: 42px;
	border: 2px solid #F27480;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	background-color: #fff;
	color: #f27480;
	cursor: pointer;
	outline: none;
}

.tableDiv {
	padding-right: 33px;
}
