.pageContainer {
	display: flex;
	justify-content: space-between;
	height: 100%;
	min-height: calc(100vh - 80px);
}

.sideBarContainer {
	width: 269px;
	margin-right: 20px;
}

.filtersListContainer {
	margin-bottom: 17px;
	height: fit-content;

	&Home {
		margin-right: 33px;
		margin-left: 23px;
	}
}

.contentContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

@media screen and (min-width: 1400px){
	.pageContainer {
		justify-content: inherit;
	}
}

@media screen and (max-width: 1300px) {
	.filtersListContainerHome {
			margin-right: 8px;
		}
}
