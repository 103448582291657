@import '../../constants/globalConstants.scss';

.formContainer {
	padding: 30px 97px 68px 68px;
	width: 624px;

	background: $form-container-color;
	border: 1.5px solid $form-border-color;
	box-sizing: border-box;

	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;

	margin: 80px auto;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.formHeading {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 33px;

	display: flex;
	align-items: center;

	margin: 0;
	color: $text-color;
}

.personalFieldName {
	display: block;
	text-align: left;
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;

	color: #1e496b;
	margin-top: 20px;
}

.formLabel {
	display: block;
	height: 70px;
}

.formInput {
	box-sizing: border-box;
	text-indent: 27px;
	display: block;
	margin-top: 8px;
	width: 459px;
	height: 47px;

	font-family: 'Open Sans', sans-serif;
	background: $form-container-color;
	border: 2px solid $input-border-color;
	box-sizing: border-box;
	border-radius: 3px;
	transition: border 0.3s;
}

.formInput:focus {
	border: 2px solid #007ad9;
	outline: none;
}

.formInput_invalid {
	border: 2px solid #ff0000;
}

.formInput::placeholder {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	color: rgba(126, 147, 168, 0.5);
}

.togglePassword {
	display: block;
	width: 15px;
	height: 14px;
}

.formButton {
	position: relative;
	bottom: 34px;
	left: 180px;

	background-color: $form-container-color;
	border: none;
	outline: none;
	cursor: pointer;
}

.submitButton {
	display: block;
	margin-top: 60px;
	width: 100%;
	height: 56px;
	line-height: 56px;

	background: $pink-color;
	border-radius: 6px;
	border: none;
	outline: none;

	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;

	display: flex;
	align-items: center;
	text-align: center;

	color: $form-container-color;
	cursor: pointer;
}

.buttonText {
	display: block;
	text-align: center;
	margin: auto;
}
