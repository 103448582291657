.filterContainer {
	padding: 28px 20px;
	width: auto;
}

.filters {
	margin-top: 25px;
}

.filterName {
	display: flex;
	justify-content: space-between;
}

.categoryLabel {
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #1e496b;
}

.dropDownButton {
	width: 23px;
	height: 23px;
	background-image: url("/img/dropdown.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-color: #f3f3f3;
	outline: none;
	cursor: pointer;
	border:none;
	border-radius: 50%;
	transition: 0.3s;
}

.filterField {
	display: flex;
	align-items: center;
	margin-bottom: 16px;

	&Country:nth-child(2) {
		margin-bottom: 66px;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.divider {
	opacity: 0.3;
	border: 0.5px solid #8c8c8c;
	margin: 0;
}

.ageField {
	width: 68px;
	height: 37px;
	padding: 10px;
	background: #fff;
	border: 1.5px solid #b9bdc1;
	box-sizing: border-box;
	border-radius: 5px;
}

.ageFieldsDivider {
	width: 10px;
	height: 0;
	border: 1px solid #a6a6a6;
	margin: 0 7px;
}

.dateFieldsDivider {
	//width: 10px;
	height: 0;
	border: 1px solid #a6a6a6;
	margin: 0 7px;
}

.ageConfirmButton {
	width: 49px;
	height: 37px;
	background: #f27480;
	border-radius: 5px;
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	margin-left: 14px;
	border: none;
	outline: none;
	cursor: pointer;
}

.customCheckbox {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.customCheckbox+label {
	display: inline-flex;
	align-items: center;
	user-select: none;
	cursor: pointer;

	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	text-transform: capitalize;
	color: #25213b;
}

.customCheckbox+label::before {
	content: '';
	display: inline-block;
	width: 28px;
	height: 28px;
	background-color: #fff;
	border: 1.5px solid #b9bdc1;
	box-sizing: border-box;
	border-radius: 5px;
	margin: 0 12px 0 0;
	cursor: pointer;
	flex-shrink: 0;
	flex-grow: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
}

.customCheckbox:checked+label::before {
	background-image: url("/img/flag.svg");
	background-color: #F27480;
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.datesInput {
	height: 37px;
	width: 73px;
	background: #fff;
	border: 1.5px solid #b9bdc1;
	box-sizing: border-box;
	border-radius: 5px;
}
