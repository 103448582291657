@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import "../../constants/globalConstants";

.card {
	flex-basis: auto;
	display: flex;
	flex-direction: column;
	background: $form-container-color;
	box-shadow: 0px 4px 20px rgba(39, 42, 47, 0.13);
	border-radius: 8px;
	margin: 15px;
	max-width: 493px;

	&__details {
		padding-left: 24px;
	}

	&__logo {
		width: 100%;
	}

	&__heading {
		font-family: 'Montserrat', sans-serif;
		font-size: 22px;
		font-weight: 500;
		line-height: 25px;
		color: $card-heading-color;
		margin-top: 16px;
	}

	&__position {
		background-color: $card-position-color;
		border-radius: 4px;
		width: 169px;
		height: 24px;

		font-family: 'Open Sans', sans-serif;
		font-weight: 700;
		color: $form-container-color;
		font-size: 10px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.2em;
		margin-top: 21px;
	}

	&__hired {
		display: block;
		font-family: 'Montserrat', sans-serif;
		font-size: 30px;
		line-height: 25px;
		letter-spacing: 0.02em;
		color: $text-color;
		margin-top: 27px;
	}

	&__hired_small {
		margin: 0;
		opacity: 0.5;
		font-size: 14px;
	}

	&__line {
		width: 80%;
		margin: auto;
		border: 1px solid $line-color;
		margin-top: 12px;
	}

	&__description {
		font-family:'Open Sans', sans-serif;
		font-size: 15px;
		line-height: 35px;
		color: $card-text-color;

		padding: 50px 77px 67px;
		position: relative;
	}

	&__description::before {
		content: '\201C';
		font-family: 'DM Serif Display', sans-serif;
		font-size: 75px;
		line-height: 25px;
		color: $pink-color;
		position: absolute;
		top: 30px;
		left: 30px;
	}

	&__description::after {
		content: '\201C';
		font-family: 'DM Serif Display', sans-serif;
		font-size: 75px;
		line-height: 25px;
		color: $pink-color;
		position: absolute;
		bottom: 30px;
		right: 30px;
	}
}

.card:last-child {
	margin-right: 0;
}

@media screen and (max-width: 1300px) {
	.card__description {
		padding: 8px;
	}

	.card__description::before {
		content: '';
	}

	.card__description::after {
		content: '';
	}
}

@media screen and (max-width: 1300px) {
	.card__details {
		padding: 5px 5px;
	}
}
