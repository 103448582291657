.preloaderContainer {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	top: 0;
	min-height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	z-index: 100;
}
