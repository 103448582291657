@import '../../constants/globalConstants.scss';

.formContainer {
	padding: 30px 97px 68px 68px;
	width: 624px;
	height: 724px;

	background: $form-container-color;
	border: 1.5px solid $form-border-color;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;

	margin: 80px auto;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.formHeading {
	font-family: 'Inter', sans-serif;
	font-weight: bold;
	font-size: 24px;
	line-height: 33px;

	display: flex;
	align-items: center;

	margin: 0;
	color: $text-color;
}

.personalData {
	background: $sidebar-color;
	border: 2px solid $input-border-color;
	border-radius: 3px;
	width: 459px;
	height: 47px;
	margin: 8px 0;

	&__text {
		display: block;
		text-align: left;
		line-height: 43px;
		margin-left: 27px;

		font-family: 'Montserrat', sans-serif;
		font-weight: 600;
		font-size: 13px;
		color: #333;
	}
}

.personalFieldName {
	display: block;
	text-align: left;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;

	color: $main-color;
	margin-top: 20px;
}

.personalFieldName.nameConfirm{
	margin-top: 5px;
}

.formLabel {
	display: block;
	height: 70px;
}

.formInput {
	text-indent: 27px;
	margin-top: 8px;
	width: 459px;
	height: 47px;

	font-family: 'Open Sans', sans-serif;
	background: $form-container-color;
	border: 2px solid $input-border-color;
	border-radius: 3px;
	transition: border 0.3s;
}

.formInput:focus {
	border: 2px solid $input-focus-border;
	outline: none;
}

.formInput_invalid {
	border: 2px solid #ff0000;
}

.formInput::placeholder {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	font-size: 13px;
	color: rgba(126, 147, 168, 0.5);
}

.togglePassword {
	display: block;
	width: 15px;
	height: 14px;
}

.errorMessage {
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	color: #f27480;
	height: 13px;
	display: block;
	text-align: left;
	margin-top: 5px;
}

.formButton {
	position: relative;
	bottom: 33px;
	left: 180px;

	background-color: $form-container-color;
	border: none;
	outline: none;
	cursor: pointer;
}

.submitButton {
	margin-top: 60px;
	width: 100%;
	height: 56px;
	background: $pink-color;
	border-radius: 6px;
	border: none;
	outline: none;

	font-family: 'Inter', sans-serif;
	font-weight: bold;
	font-size: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: $form-container-color;
	cursor: pointer;
}

.buttonText {
	text-align: center;
	margin: auto;
}
