@import '../../constants/globalConstants.scss';

.container {
	width: 624px;
	height: 580px;

	background: $form-container-color;
	border: 1.5px solid $form-border-color;
	box-sizing: border-box;

	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;

	margin: auto;
	display: flex;
	flex-direction: column;
	padding: 50px 70px;
}

.recovery_heading {
	font-family: 'Inter', sans-serif;
	font-weight: bold;
	font-size: 24px;
	line-height: 33px;
}

.recovery_text {
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	line-height: 24px;
	color: $form-text-color;
}

.recovery_input {
	text-indent: 27px;
	margin-top: 8px;
	width: 100%;
	height: 47px;

	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	background: $form-container-color;
	border: 2px solid $input-border-color;
	border-radius: 3px;
	transition: border 0.3s;
}

.recovery_input:focus {
	border: 2px solid $input-focus-border;
	outline: none;
}

.recovery_input_invalid {
	border: 2px solid red;
}

.recovery_input::placeholder {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	font-size: 13px;
	color: rgba(126, 147, 168, 0.5);
}

.submitButton {
	margin-top: 150px;
	height: 56px;
	background: $pink-color;
	border-radius: 6px;
	border: none;
	outline: none;
	font-family: 'Inter', sans-serif;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;

	color: $form-container-color;
	cursor: pointer;
	transition: background 0.3s ease;

	&:disabled {
		background: $button-disable-color;
		cursor: auto;
	}
}

.recovery_section {
		height: calc(100vh - 80px);
    display: flex;
    align-items: center;
		justify-content: center;
    background-color: #F8FAFD;
}
