@import "../../constants/globalConstants";
@import "src/components/Filter/Filter.module";

.sidebarContainer {
  height: 100%;
  width: 269px;
  background-color: #f3f3f3;
	scroll-margin-right: 4px;
	overflow-y: scroll;
	overflow-x: hidden;

	//for Firefox
	scrollbar-color:#c4c4c4 #f3f3f3;
	scrollbar-width:4px
}

::-webkit-scrollbar-thumb {
	width: 4px;
	background: #c4c4c4;
	border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover{
	background-color:#1e496b;
}

::-webkit-scrollbar{
	width: 4px;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 12px 40px;
  box-sizing: border-box;
  width: 100%;
  transition: background-color 0.3s ease-in-out;

  span {
    color: $main-color;
    padding-left: 15px;
    transition: color 0.3s ease-in-out;
  }

  &:hover {
    background-color: darken($sidebar-color, 10%);
  }

  &:hover span {
    color: $main-color;
  }
}

.activeLink {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: darken($sidebar-color, 10%);
  padding: 12px 40px;
  box-sizing: border-box;
  width: 100%;
  transition: background-color 0.3s ease-in-out;

  span {
    color: $main-color;
    padding-left: 15px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: $main-color;
  }
}

.divider {
	opacity: 0.3;
	border: 0.5px solid #8c8c8c;
	margin: 0;
}

.filterContainerDetailed {
	padding: 33px 0 33px 20px;
}

.detailedCheckbox>input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.detailedCheckbox>span {
	display: inline-flex;
	align-items: center;
	user-select: none;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #1e496b;
	cursor: pointer;
}

.detailedCheckbox>span::before {
	content: '';
	display: inline-block;
	width: 28px;
	height: 28px;
	background: #fff;
	border: 1.5px solid #b9bdc1;
	box-sizing: border-box;
	border-radius: 5px;
	margin: 0 12px 0 0;
	cursor: pointer;
	flex-shrink: 0;
	flex-grow: 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
}

.detailedCheckbox>input:not(:disabled):not(:checked)+span:hover::before {
	border-color: #b3d7ff;
}

.detailedCheckbox>input:not(:disabled):active+span::before {
	background-color: #b3d7ff;
	border-color: #b3d7ff;
}

.detailedCheckbox>input:checked+span::before {
	background-image: url("/img/flag.svg");
	background-color: #F27480;
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
}
