.tableContainer {
	width: 100% ;
	height: 100%;
}

.tableHeading {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-right: 33px;
	margin-bottom: 18px;
}

.tableHeadingName {
	align-self: flex-start;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	color: #1e496b;
	margin: 0 0 30px 0;
}

.tableHeadingBtnContainer {
	position: absolute;
	right: 33px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.inviteBtn {
	align-self: flex-end;
	width: 234px;
	height: 42px;
	margin-left: 20px;
	background: #f27480;
	border-radius: 5px;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	color: #fff;
	border: none;
	cursor: pointer;
	outline: none;
}

.showBtn {
	align-self: flex-end;
	height: 42px;
	border: 2px solid #F27480;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	background-color: #fff;
	color: #f27480;
	cursor: pointer;
	outline: none;
}

.tableDiv {
	padding-right: 33px;
}

.searchContainer {
	position: relative;
	width: 392px;
	border-radius: 6px;
}

.searchIcon {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 20px;
	height: 20px;
}

.searchBar {
	width: 100%;
	padding: 0 10px 0 40px;
	height: 40px;
	background: #f3f3f3;
	border-radius: 6px;
	border: none;
	outline: none;

	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;

	&::placeholder {
		color: #647c8e;
	}
}
