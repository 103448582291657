.jitsi {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #1E496B;
	
	&__background {
		padding: 50px calc(50% - 640px) 70px;
		width: 100%;
	}
	
	&__container {
		background-color: #282c34;
		width: 100%;
		height: calc(100vw * 0.4);
	}
}


.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 69px;
	width: 100%;
	background: #DAE1E8;
	
	&__text {
		display: block;
		max-width: 200px;
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #B4BFCA;
	}
	
	&__line {
		display: block;
		border: 1px solid #B4BFCA;
		height: 31px;
		margin: 0 20px;
		box-shadow: none;
	}
}
